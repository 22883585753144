<template>
    <div class="">
        <LayoutHeader />
        <main class="mt-18 lg:mt-22.5 max-w-full">
            <slot />
        </main>
        <LayoutFooter />
        <div v-if="showScrollButton" class="z-5">
            <div @click="scrollToTop"
                class="box-shadow-scroll-top w-12 h-12 <md:w-10 <md:h-10 flex items-center justify-center rounded-full fixed z-5 bottom-20 sm:bottom-6 right-6 bg-white transition-all duration-200 ease hover:scale-105 cursor-pointer">
                <Icon name="material-symbols:fitbit-arrow-upward-rounded" class="text-white text-7 <md:text-6" />
            </div>
        </div>
        <div class="container fixed bottom-5 z-5 sm:hidden">
            <div class="flex justify-center">
                <app-button :title="t('GET_STARTED')" :link="dataConfig?.link_button" target="_blank"
                    class="" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">

const { t } = useI18n();
const scrollPosition = ref(0);
const showScrollButton = ref(false);

const appConfig = useState("app_config")
const dataConfig = appConfig.value?.data

onMounted(async () => {
    window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
    scrollPosition.value = window.scrollY || window.pageYOffset;
    showScrollButton.value = scrollPosition.value > 200;
};

const scrollToTop = () => {
    window.scrollTo({
        top: 0,  // Vị trí đích (đầu trang)
        behavior: 'smooth'  // Cuộn mượt mà
    });
};
</script>
<style scoped>
.box-shadow-scroll-top {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background: #544DE9;
    border: 2px solid white;
}
</style>
